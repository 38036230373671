.header-container {
  width: 100%;
  height: 90px;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 92;
}
.inner-header-container {
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 100px;
}
.one-div {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one-div img {
  width: 120px;
  /* margin-left: -20px; */
}
.two-div {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .two-div i{
  position: absolute;
} */
.div {
  width: 100%;
  height: 43%;
  border: 1px solid #ebb9c1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #5d2c2d;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.div p span {
  font-weight: 500;
}

.three-div {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.btn {
  background: #630000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 1px;
}
.burger-box {
  width: 43px;
  height: 33px;
  border: 1px solid #ebb9c1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}
.burger-line {
  width: 3px;
  height: 2px;
  background: #630000;
  position: relative;
}
.burger-line-2 {
  width: 22px;
  height: 2px;
  background: #630000;
}
.burger-line::after {
  content: "";
  position: absolute;
  width: 28px;
  height: 2px;
  background: #630000;
  top: 8px;
}
.burger-line::before {
  content: "";
  position: absolute;
  width: 28px;
  height: 2px;
  background: #630000;
  bottom: 8px;
}
.dropdown-menu {
  position: absolute;
  width: 1000px;
  height: 400px;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 332;
  right: -325px;
  top: 65px;
  cursor: default;
}
.dm-hide {
  display: none;
}
.dm-1,
.dm-2,
.dm-3,
.dm-4 {
  width: 26%;
  height: 260px;
  /* background-color: #5d2c2d; */
  color: #fff;
}
.dm-1-1 {
  width: 85%;
  height: 35px;
  margin-top: -60px;
  letter-spacing: 2px;
  /* background-color: #ebb9c1; */
  border-bottom: 1px solid;
}
.dm-1-2 {
  width: 300px;
  height: 280px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.dm-1-2 p {
  font-size: 14px;
  margin-left: 5px;
  letter-spacing: 1.5px;
}
.dm-1-1 h5,
p {
  cursor: pointer;
}
.dm-2 .dm-1-2 {
  width: 200px;
}
.burger-box {
  position: relative;
}
.fa-xmark {
  font: 64px;
}
.side-bar {
  width: 200px;
  /* height: 100px; */
  background-color: #5d2c2d;
  z-index: 4444300;
  position: absolute;
  top: 90px;
  right: 0px;
  text-align: center;
  color: #fff;
}
/* .dm-3 .dm-1-2{
  width:350px;
} */
.dm-3 .dm-1-1 {
  width: 100%;
  margin-top: -60px;
  letter-spacing: 1.5px;
  /* background-color: #ebb9c1; */
  border-bottom: 1px solid;
}
@media (max-width: 928px) {
  .div p {
    font-size: 14px;
  }
}
@media (max-width: 796px) {
  .div p {
    font-size: 13px;
  }
}
@media (max-width: 738px) {
  .two-div {
    display: none;
  }
}
@media (max-width: 562px) {
  .one-div {
    width: 40%;
  }
  .three-div {
    width: 40%;
  }
}
@media (max-width: 420px) {
  .one-div {
    width: 40%;
  }
  .three-div {
    width: 50%;
  }
}
@media (max-width: 384px) {
  .one-div {
    width: 35%;
  }
  .three-div {
    width: 60%;
  }
}
@media (max-width: 674px) {
  .one-div img {
    width: 100px;
    /* background-color: #630000; */
  }
}
