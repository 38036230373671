.f-1 {
  width: 100%;
  height: 220px;
  /* background-color: aqua; */
}
.inner-f-1 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: #fff;
  /* align-items: center; */
}
.wraper-f-1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.input-wraper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 950px;
  background-color: #630000;
}
.inner-form-container {
  max-width: 1920px;
  height: 100%;
  /* background-color: #f7e5e5; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.wraper-fo-c {
  width: 40%;
  height: 100%;
  /* background-color: #f01f1f; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 20px;
}
.div-g {
  margin-top: -10px;
  width: 87%;
  height: 58px;
  /* background-color: #f7e5e5; */
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 0px 20px;
  color: #fff;
}
.div-f {
  position: relative;
  height: 80px;
  line-height: 60px;
}
.div-f input {
  position: absolute;
  width: 400px;
  height: 60px;
  outline: none;
  padding: 0 30px;
  line-height: 80px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  transition: 0.1s ease;
  z-index: 1111;
  color: #fff;
}
.labelline {
  position: absolute;
  color: #fff;
  padding: 0px 15px;
  margin: 0 20px;
  background-color: #630000;
  transition: 0.2s ease;
}
.l-2 {
  background-color: #630000;
  color: #f7e5e5;
}
input:focus,
input:valid {
  border: 2px solid #fff;
}
.wraper-fo-c-2 input {
  border: 2px solid #f7e5e5;
  color: #f7e5e5;
}
input:focus + .labelline,
input:valid .labelline {
  color: #fff;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99999;
}
input:focus + .l-2,
input:valid .l-2 {
  color: #f7e5e5;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99999;
}

input:valid + .fix {
  color: #fff;
  line-height: 30px;
  transform: translate(-15px, -16px) scale(0.88);
  z-index: 99999;
}
.ff input {
  color-scheme: dark;
}

.bank-detail-container {
  width: 100%;
  height: 150px;
  /* background-color: #001063; */
}
.inner-b-d-c {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.w-inner-b-d-c {
  width: 83%;
}

.form-container-2 {
  width: 100%;
  height: 350px;
  /* background-color: aqua; */
}
.inner-form-container-2 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;

  justify-content: center;
}
.f-2-heading-c {
  width: 80%;
  font-size: 25px;
  /* color: #630000; */
  text-align: justify;
}
.wraper-fo-c-2 {
  width: 40%;
}

.submit-d-container {
  width: 100%;
  height: 80px;
  /* background-color: #001063; */
}
.inner-s-d-c {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  text-align: center;
}

.inner-s-d-c button {
  background-color: #f7e5e5;
  border-radius: 100px;
  width: 150px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #630000;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  /* padding: 7px 20px; */
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 20px;
}

.inner-s-d-c button:hover {
  transform: scale(1.05) rotate(-1deg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
