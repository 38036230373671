.p-f-container {
  width: 100%;
  min-height: 550px;
  /* background-color: aqua; */
}

.inner-p-f {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #630000;
  padding: 20px;
}
.div-p-f,
.div-b-pf {
  width: 80%;
}
.div-p-f p {
  text-align: justify;
}
.div-b-pf {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.div-b-pf button {
  width: 150px;
  height: 30px;
}

/* <!-- HTML !-->
<button class="button-33" role="button">Button 33</button> */

/* CSS */

.div-b-pf button {
  background-color: #630000;
  border-radius: 100px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #f7e5e5;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.div-b-pf button:hover {
  transform: scale(1.05) rotate(-1deg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* //=========> second page css start here <=========== */

.pr-p-2-container {
  width: 100%;
  min-height: 1600px;
  background-color: #f7e5e5;
}
.inner-pr-p-2 {
  max-width: 1920px;
  height: 100%;
  /* background-color: #630000; */
  margin: 0 auto;
}
.pr-p2-div {
  width: 100%;
  min-height: 300px;
  /* background-color: #630000; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-pr-p2-div {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #630000; */
}
.fi {
  display: flex;
  align-items: center;
}
.inner-pr-p2-div h3 {
  color: #630000;
  text-align: justify;
  line-height: 30px;
}
.bg {
  background-color: #630000;
  color: #f7e5e5;
}
.bg h3 {
  color: #f7e5e5;
}
/* .inner-pr-p2-div .changec{
  color: #630000;
} */
.inner-pr-p2-div p {
  color: #fff;
  text-align: justify;
}

.adhyayan-off {
  width: 100%;
  height: 200px;
  /* background-color: #f7e5e5; */
  /* background-color: aqua; */
}
.inner-ad-off {
  max-width: 1920px;
  height: 100%;
  /* background-color: aqua; */
  margin: 0 auto;
  display: flex;

  justify-content: center;
}
.div-ad-off {
  width: 80%;
  height: 100%;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.ad-para-content {
  width: 100%;
  min-height: 550px;
  /* background-color: #fff; */
}
.inner-ad-para {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
}

.w-ad-para {
  width: 80%;
  height: 100%;
  /* background-color: #630000; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.ad-para-con-2 {
  width: 100%;
  min-height: 350px;
  background-color: #630000;
}
.inner-ad-p-2 {
  max-width: 1920px;
  height: 100%;
  /* background-color: aqua; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-inner-ad-p-2 {
  width: 80%;
  color: #f7e5e5;
}

.c-div-btn {
  width: 100%;
  height: 80px;
  background-color: #630000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CSS */
.c-div-btn button {
  background-color: #f7e5e5;
  border-radius: 100px;

  color: #630000;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.c-div-btn button:hover {
  transform: scale(1.05) rotate(-1deg);
}

/* //===========>PAGE CSS START HERER <============== */

.p-h-s {
  width: 100%;
  min-height: 100px;
  /* background-color: aquamarine; */
}
.inner-p-h-s {
  max-width: 1920px;
  height: 100%;
  background-color: #f7e5e5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: inherit;
  color: #630000;
  padding: 20px 0px;
}
.s-ph-s {
  width: 80%;
}

.ph-s-2 {
  width: 100%;
  min-height: 280px;
  /* background-color: #006360; */
}
.inner-ph-s-2 {
  max-width: 1920px;
  height: 100%;
  /* background-color: #fff; */
  background-color: #f7e5e5;
  color: #630000;
  margin: -1px auto;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.width-ph-s-2 {
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-top: -1px;
}

.ph-s-3 {
  width: 100%;
  min-height: 250px;
  /* background-color: aqua; */
}
.inner-ph-s-3 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.w-p-s-3 {
  width: 80%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.i-sa {
  background-color: #f7e5e5;
}
p {
  cursor: text;
}
.f-h {
  height: 800px;
}
.f-h p {
  text-align: justify;
}
/* // media query start here */
@media (max-width: 568px) {
  .ph-s-3 {
    height: 350px;
  }
}
@media (max-width: 472px) {
  .ph-s-3 {
    height: 400px;
  }
}
@media (max-width: 406px) {
  .ph-s-3 {
    height: 430px;
  }
}
@media (max-width: 322px) {
  .ph-s-3 {
    height: 480px;
  }
}

@media (max-width: 896px) {
  .f-h {
    height: 700px;
  }
}
@media (max-width: 630px) {
  .f-h {
    height: 730px;
  }
}
@media (max-width: 574px) {
  .f-h {
    height: 780px;
  }
}
@media (max-width: 532px) {
  .f-h {
    height: 830px;
  }
}
@media (max-width: 500px) {
  .f-h {
    height: 860px;
  }
}
@media (max-width: 440px) {
  .f-h {
    height: 930px;
  }
}
@media (max-width: 392px) {
  .f-h {
    height: 990px;
  }
}

.ph-s-4 {
  width: 100%;
  min-height: 290px;
}
.inner-ph-s-4 {
  max-width: 1920px;
  height: 100%;
  background-color: #630000;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.w-ph-s-4 {
  width: 80%;
  padding: 20px 0px;
}
.bt-div-ph {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
/* // media query start here  */
@media (max-width: 500px) {
  .bt-div-ph {
    flex-direction: column;
    gap: 0px;
  }
}

.bt-div-ph button {
  background-color: #f7e5e5;
  border-radius: 100px;
  width: 110px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #630000;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  /* padding: 7px 20px; */
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.bt-div-ph button:hover {
  transform: scale(1.05) rotate(-1deg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.c-h-r {
  text-decoration: none;
  color: #fff;
  text-align: center;
}

.l-b {
  height: 200px;
}

@media (max-width: 506px) {
}


.p-wrap{
  width: 100%;
  height: 100%;
  /* background-color: #11876c; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 20px;
}

.w-image{
  width: 40%;
  height: 200px;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.for-p{
  width: 60%;
  height: 100%;
  /* background-color: #fff; */
}