.ln-container {
  width: 100%;
  height: 500px;
  /* background-color: red; */
}
.inner-ln-container {
  max-width: 1920px;
  height: 100%;
  background-color: #ffffff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.inner-ln-container h2 {
  text-align: center;
  letter-spacing: 1px;
  font-size: 28px;
  color: #630000;
}
.card-div {
  width: 90%;
}

.card-box {
  width: 300px;
  height: 360px;
  background-color: white;
  border: 1px solid rgb(200, 191, 191);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  cursor: pointer;
}
.card-box img {
  width: 100%;
}
.card-box-div {
  padding: 0px 10px;
}

@media (max-width: 680px) {
  .card-box {
    width: 280px;
    height: 350px;
  }
}
@media (max-width: 630px) {
  .card-box {
    width: 260px;
    height: 350px;
  }
}
@media (max-width: 594px) {
  .card-box {
    width: 240px;
    height: 350px;
  }
}
@media (max-width: 552px) {
  .card-box {
    width: 220px;
    height: 330px;
  }
}
@media (max-width: 500px) {
  .card-box {
    width: 200px;
    height: 300px;
  }
  .card-box-div p {
    font-size: 13px;
  }
}
@media (max-width: 448px) {
  .card-box {
    width: 180px;
    height: 260px;
  }
  .card-box-div p {
    font-size: 13px;
  }
  .card-box-div {
    padding: 5px 10px;
  }
  .card-box-div h4 {
    margin-top: -10px;
  }
}
@media (max-width: 418px) {
  .card-box {
    width: 160px;
    height: 240px;
  }
  .card-box-div p {
    font-size: 12px;
  }
  .card-box-div {
    padding: 10px 10px;
  }
  .card-box-div h4 {
    font-size: 14px;
    /* margin-top: -5px; */
  }
}
@media (max-width: 350px) {
  .card-box {
    width: 120px;
    /* height: 220px; */
  }
  .card-box-div p {
    font-size: 10px;
  }
  .card-box-div {
    padding: 0px 10px;
  }
  .card-box-div h4 {
    font-size: 12px;
    margin-top: 5px;
  }
}
